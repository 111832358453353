#header{
    position: relative;
	height: 500px;
	overflow: hidden;
	margin-top: 80px;
	.header{
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover;';
	}
	.header-overlay{
		position: absolute;
		height: auto;
	}
	.atomium{
	    width: 700px;
	    top: auto;
	    bottom: -6em;
	    left: -2em;

		@include md{
			width: 700px;
			top: -1em;
			left: 2em;
		}
	}
	.notice{
		width: 300px;
	    right: 0;
	    top: 1em;
	    // transform: translate(2px, 2px);

		@include md{
			width: 450px;
		    top: auto;
			bottom: 0;
		}
	}

}