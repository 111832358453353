.inner-animation{
	transition: all .8s;
	&.not-visible{
		opacity: 0;
		transform: translateY(-1em)!important;
	}
	&.not-visible-header{
		opacity: 0;
	}
}

.full-height{
	height: auto!important;
}

.box-shadow{
	box-shadow: 1px 1px 1px rgba(0,0,0,.2);
}