.heading{
	font-family: $open;
	color: $orange;
	font-size: 3.2rem;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	margin-bottom: 0;
}
.subheading{
	color: $blue;
	font-size: 2.2rem;
}
p{
	font-size: 1.6rem;
	font-family: $open;
	font-weight: 100;
}
strong{
	color: #1d3b75;
}