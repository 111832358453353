.gform_wrapper{
	font-size: 1.4rem;
	margin-bottom: 0!important;

	.gfield label{
		// display: none!important;
	}
	.no-label{
		> label{
			display: none!important;
		}
	}
	form .gform_body{
		width: 100%!important;
	}
	form .gform_body .ginput_complex input[type=text] {
		width: 100%!important;
	}
	input[type=text], select, textarea {
		width: 100%!important;
		padding: .5em 1em!important;
		border: 1px solid $lightest-gray;
		margin-bottom: .5em;
		transition: all .3s;
		&:hover, &:active, &:focus{
			border: 1px solid $orange;
		}
	}
	ul.gform_fields li.gfield{
		padding: 0!important;
		margin: 0!important;
	}
	.top_label .gfield_label{
		display: none;
	}
	input[type=submit]{
		padding: .5em 1em;
		background: $orange;
		color: $white;
	}
	.gform_footer{
		padding-bottom: 0!important;
		margin: 0!important;
	}
	.gfield_html, label{
		color: $blue;
	}

	// CHECKBOX CHECKED STATE
	input[type=checkbox]:checked + label {
		font-weight: 100!important;
	}

	// .gfield_contains_required .ginput_container{
	// 	&:before{
	// 		content: '*';
	// 		position: absolute;
	// 		transform: translate(.4em, .6em);
	// 		color: $orange;
	// 	}
	// }
}