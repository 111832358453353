.heading-before{
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: calc(100% + 1em);
		height: 20px;
		background: $orange;
		opacity: .8;
		transform: translate(-1em, -1.3em);
	}
}