.hamburger{
	position: absolute;
	right: 0;
	z-index: 99999;

	transform: scale(0.4);
	padding-right: 0!important;
	right: 0!important;
	top: 15px!important;

	@media(min-width: 1040px){
		display: none;
	}
}
