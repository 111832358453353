#content{
	.block{
		position: relative;
		.header{
			width: 100%;
			height: 200px;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			object-position: 50% 20%;
			@include md{
				height: 400px;
			}
		}
		.inner-content{
			position: relative;
			padding: 2em 1em;
			margin: auto;
			min-height: 200px;
			background: white;

			@include md{
				padding: 2em 2em 1em 2em;
				transform: translateY(-4em);
				margin-bottom: -1em;
				margin-right: 0;
			}
		}
	}
}
