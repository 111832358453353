nav{
	background-color: $white;
	font-size: 1.4rem;
	display: flex;
	align-items: center;
	height: 80px;

	#left-container{
		margin-left: auto;

		.language-menu{
			display: flex;
			color: $black;
			position: relative;
		    padding-bottom: 1em;
		    margin-bottom: 1em;
			&:after{
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 1px;
				background: $lightest-gray;
			}

			@media(min-width: 1040px){
				padding-bottom: 0;
				margin-bottom: 0;
				&:after{
					display: none;
				}
			}

			.inner{
				display: flex;
				margin-left: auto;
				ul{display: inline;}
			}
		}

		.menu{
			display: inline-block;
			@include lg{
				margin-left: auto;
			}
		}
		ul{
			margin-top: 1em;
			overflow: visible;
			padding: 0;
			margin: 0;
	    	@include transition(.2s);
			li{
				padding: 0.5em .5em;
			}
			@media(min-width: 1040px){
				margin-top: 0;
	    		height: auto;
				background: none;
				li{
					display: inline-block;
					padding: 0 0 0 1.5em;
				  	&:last-child{
						border-right: none;
						margin-right: 0;
					  	border-bottom: 0;
					}

				}
			}
			a{
				color: $black;
				transition: all .3s;
				&:hover{
					color: $orange;
				}
			}
		}
	}
}
#nav-wrapper{
	background: $white;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
	transition: all .1s;
}
.nav-menu{
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100vh + 5rem);
	width: 100%;
	z-index: 9999;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	background: $white;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s;

	@media(min-width: 1040px){
		display: block;
		height: auto;
		width: 100%;
		position: relative;
		opacity: 1;
		pointer-events: auto;
		text-align: left;
	}
	@include lg{
	}
}