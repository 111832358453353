.wysiwyg{
	img.alignright { float: right; margin: 0 0 1em 1em; }
	img.alignleft { float: left; margin: 0 1em 1em 0; }
	img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
	.alignright { float: right; }
	.alignleft { float: left; }
	.aligncenter { display: block; margin-left: auto; margin-right: auto; }
	.column{
		padding: 0;
	}
	img{
		max-width: 100%!important;
		&.size-full{
			width: 100%!important;
			max-width: 650px!important
		}
	}
	ul, ol{
		font-size: 1.6rem;
		color: $text-color;
		li{
			list-style: none;
		}
	}
	ul{
		li{
			&:before{
				content: "• "; 
				// font-family:"Webdings";
				color: $orange;
				position: absolute;
				transform: translateX(-1em);
			}
		}
	}
	ol{
		li{
			list-style: decimal;
		}
	}

	h3{
		color: $blue;
		font-size: 2.2rem;
	}
	strong{
		font-weight: 500;
		color: $orange;
	}
}