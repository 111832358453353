#sidebar{
	position: relative;
	z-index: 2;
	.contact{
		padding: 2em 0;
		padding-left: 1.5rem;
		transition: all .3s;
		background: $light-blue;
		padding-right: 1.5rem;

		@include md{
			width: calc(100% + 3rem);
			transform: translate(-1.5rem, 0);
		}

		.subheading{
			max-width: 270px;
		}
	}
}