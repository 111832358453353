.hidden {
	display: none !important;
	visibility: hidden;
}

.disable-scroll {
	overflow: hidden;
}

.show-nav{
	// transform: translateY(0%)!important;
	opacity: 1!important;
	pointer-events: auto!important;
	ul{
		transform: translateY(0)!important;
	}
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}