html, body{
	font-family: $open;
	font-weight: 100;
	color: $text-color;
	overflow-x: hidden;
}

a{
	color: $text-color;
	transition: all .3s;
	&:hover{
		color: $orange;
	}
	&:active, &:focus, &:visited{
		outline: none;
	}
}