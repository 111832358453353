footer{
	#footer1{
		> div{
			position: relative;
			overflow: hidden;
		}
		.header{
			width: 100%;
			height: 400px;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
		.atomium{
		    width: 700px;
		    top: auto;
		    bottom: -6em;
		    left: -2em;
		    position: absolute;

			@include md{
				width: 500px;
				top: -1em;
				left: 2em;
			}
		}
	}
	#footer2{
		& > div:first-child{
			@include md{
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.left-container{
				width: 100%;
			.logo{
				margin: 1em auto;
				display: block;
				padding: 0;
				@include md{
					width: 300px;
					margin: 1em 0;
				}
			}
			p{
				font-size: 1.2rem;
				max-width: 500px;
				text-align: center;
				@include md{
					text-align: left;
					margin-top: 3em;
				}
			}
		}
		.right-container{
			margin-left: auto;
			padding: 1em 0;
			text-align: center;
			@include md{
				width: 400px;
				display: inline-block;
				text-align: right;
			}
		}
		#footer-links{
			a{
				font-size: 1.2rem;
				margin-left: .5rem;
				color: $light-gray;
			}
		}
	}
}