#popup {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;

	background: rgba(0,0,0,.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999999;

	opacity: 0;
	pointer-events: none;
	transition: all .3s;

	#inner-popup {
		position: relative;
		width: 500px;
		max-width: 100%;
		padding: 2em;
		background: $white;

		transform: translateY(0em);
		transition: all .3s;

		img{
			display: block;
			margin: auto;
		}
		p:last-child{
			margin-bottom: 0;
		}
	}

	#popup-close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0;
		cursor: pointer;
		transform: translate(-5px, 12px);

		span {
			height: .2em;
			width: 1em;
			background-color: $orange;
			display: block;
			transform: rotate(45deg);

			&:before {
				height: .2em;
				width: 1em;
				background-color: $orange;
				display: block;
				transform: rotate(-90deg);
				content: " ";
			}
		}
	}

	&.visible{
		opacity: 1;
		pointer-events: auto;

		#inner-popup {
			transform: translateY(2em);
		}
	}
}